import "core-js/features/array/at";
import Hotjar from "@hotjar/browser";
import { ComponentType, ReactElement, ReactNode, useEffect } from "react";
import { IconContext } from "react-icons";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import "react-dates/lib/css/_datepicker.css";
import "../styles/globals.scss";
import "@earned/wizard/src/styles/scss/globals.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import DefaultLayout from "@layouts/DefaultLayout";
import { UserProvider } from "@auth0/nextjs-auth0";
import { Toaster } from "react-hot-toast";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { HiOutlineCheckCircle } from "react-icons/hi";
import useStore from "@common/state";
import { useRouter } from "next/router";
import { themeColors } from "@common/constants";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { LaunchDarklyProvider } from "@common/components/LaunchDarklyProvider";

const queryClient = new QueryClient();

const toastOptions = {
  duration: 6000,
  style: {
    color: "white",
    background: themeColors["blue"][1],
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    WordBreak: "break-all",
    margin: 0,
    padding: "8px 12px",
  },
  icon: <HiOutlineCheckCircle color="white" size={24} />,
  success: {
    style: {
      background: themeColors["green"][3],
    },
  },
  error: {
    style: {
      background: themeColors["red"][500],
    },
  },
};

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const setIsAdvisorChatOpen = useStore((state) => state.setIsAdvisorChatOpen);
  useEffect(() => {
    const handleRouteChange = () => {
      setIsAdvisorChatOpen(false);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
      const siteId = 3645502;
      const hotjarVersion = 6;
      Hotjar.init(siteId, hotjarVersion);
    }
  }, []);
  const getLayout =
    Component.getLayout ?? ((page) => <DefaultLayout>{page}</DefaultLayout>);

  return (
    <>
      <UserProvider>
        <LaunchDarklyProvider>
          <QueryClientProvider client={queryClient} contextSharing={true}>
            <DndProvider backend={HTML5Backend}>
              <IconContext.Provider value={{ size: "1rem" }}>
                {getLayout(<Component {...pageProps} />)}
                <Toaster
                  containerClassName="ReactHotToast"
                  position="top-center"
                  toastOptions={toastOptions}
                />
                <div id="wizard-root"></div>
              </IconContext.Provider>
            </DndProvider>
          </QueryClientProvider>
        </LaunchDarklyProvider>
      </UserProvider>
    </>
  );
}

export default withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID ?? "",
  // eslint-disable-next-line @typescript-eslint/ban-types
})(MyApp as ComponentType<{}>);
