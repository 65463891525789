import { useUser } from "@auth0/nextjs-auth0";
import { useLDClient } from "launchdarkly-react-client-sdk";
import * as React from "react";

const LaunchDarklyProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useUser();
  const ldClient = useLDClient();

  React.useEffect(() => {
    if (ldClient && user?.email) {
      ldClient.identify({
        key: user.email,
        name: user.email,
        email: user.email,
      });
    }
  }, [ldClient, user]);
  return <>{children}</>;
};

export default LaunchDarklyProvider;
